
import { defineComponent, ref, onMounted, computed } from 'vue'
import Datatable from '@/views/crafted/mc-superadmin/Accounts/AccountsDatatable.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useStore } from 'vuex'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { ArrowDown } from '@element-plus/icons-vue'
import router from '@/router/clean'
import dayjs from 'dayjs'
import { Metrics, MetricsPayload } from '@/store/modules/StatisticModule'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { AccountsPayload } from '@/store/modules/AccountModule'
import { getRoleName } from '@/core/helpers/assets'
// import { ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'accounts-listing',
  components: {
    Datatable,
    ArrowDown
  },
  setup () {
    const checkedAccounts = ref([])
    const tableHeader = ref([
      // {
      //   key: 'checkbox',
      //   sortable: false
      // },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Company',
        key: 'company',
        sortable: false
      },
      {
        name: 'Email',
        key: 'email',
        sortable: false
      },
      {
        name: 'Role',
        key: 'role',
        sortable: false
      },
      {
        name: 'Created Date',
        key: 'date',
        sortable: false
      },
      {
        name: 'Status',
        key: 'activation-status',
        sortable: false
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false
      }
    ])
    const store = useStore()

    const itemsPerPage = ref<number>(10)
    const selectedPage = ref<number>(0)

    const isGetAccountsInProgress = computed(() => store.getters.isGetAccountsInProgress)
    const tableData = ref(computed(() => store.getters.getAccounts))
    const companiesCounter = computed(() => store.getters.getApiAccountsCount)
    const pageCount = computed(() => Math.ceil(companiesCounter.value / itemsPerPage.value))

    onMounted(() => {
      setCurrentPageBreadcrumbs('Accounts Listing', ['Accounts'])
      const filter: AccountsPayload = { filter: { limit: itemsPerPage.value, offset: selectedPage.value } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS, filter)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS_COUNT)
    })

    const metrics = ref(computed(() => store.getters.getMetrics)) as unknown as Metrics

    const formatDate = (dateString) => {
      const date = dayjs(dateString)
      return date.format('MMM D, HH:mm')
    }

    const dialogVisible = ref(false)
    const deleteCompanyInput = ref('')
    const accountToDeleteLabel = ref('')
    const accountToDeleteUuid = ref('')
    const deleteLoading = ref(false)

    const showDeleteCompanyModal = (command) => {
      dialogVisible.value = true
      console.log(command)
      const filter: MetricsPayload = { metrics: ['num_sessions', 'num_unique_users'], filter: { accounts: [command.uuid] } }
      store.dispatch(MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER, filter)
      accountToDeleteLabel.value = command.label
      accountToDeleteUuid.value = command.uuid
      // clear input
      deleteCompanyInput.value = ''
    }

    const onDeleteAccount = () => {
      deleteLoading.value = true
      store.dispatch(Actions.API_DELETE_ACCOUNT, accountToDeleteUuid.value).then((response) => {
        deleteLoading.value = false
        if (response.status === 200) {
          Swal.fire({
            html: 'You have successfully deleted the account <b>' + accountToDeleteLabel.value + '</b>',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          }).then(function (isOkPressed) {
            if (isOkPressed) {
              // remove from datatable as well
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].accountUuid === accountToDeleteUuid.value) {
                  tableData.value.splice(i, 1)
                }
              }
              dialogVisible.value = false
            }
          })
        }
      })
    }

    const onItemsPerPageChange = (val) => {
      search.value = ''
      itemsPerPage.value = val
      const offset = (itemsPerPage.value * (selectedPage.value - 1))
      const filter: AccountsPayload = { filter: { limit: itemsPerPage.value, offset: offset < 0 ? 0 : offset } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS, filter)
    }

    const onCurrentPageChanged = (page) => {
      selectedPage.value = page
      const offset = (itemsPerPage.value * (selectedPage.value - 1))
      const payload: AccountsPayload = { filter: { limit: itemsPerPage.value, offset: offset < 0 ? 0 : offset } }

      if (search.value !== '') {
        payload.filter.lastName = search.value
        payload.filter.firstName = search.value
        payload.filter.email = search.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS, payload)
    }

    const handleActions = (command) => {
      switch (command.action) {
        case 'overview':
          router.push('/accounts/overview/' + command.uuid)
          break
        case 'edit':
          router.push('/accounts/edit/' + command.uuid)
          break
        case 'delete':
          showDeleteCompanyModal(command)
          break
        default:
          break
      }
    }

    const search = ref<string>('')

    let searchTimer

    const searchItems = () => {
      clearTimeout(searchTimer)
      store.commit(Mutations.SET_ACCOUNTS_FETCH_IN_PROGRESS, true)
      const offset = (itemsPerPage.value * (selectedPage.value - 1))
      const payload: AccountsPayload = { filter: { limit: itemsPerPage.value, offset: offset < 0 ? 0 : offset } }

      if (search.value !== '') {
        payload.filter.lastName = search.value
        payload.filter.firstName = search.value
        payload.filter.email = search.value
      }

      searchTimer = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS, payload)
        const countPayload: AccountsPayload = { filter: { limit: itemsPerPage.value, offset: 0 } }
        if (search.value !== '') {
          countPayload.filter.firstName = search.value
          countPayload.filter.lastName = search.value
          countPayload.filter.email = search.value
        }
        store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNTS_COUNT, countPayload)
      }, 500)
    }

    return {
      tableData,
      tableHeader,
      showDeleteCompanyModal,
      search,
      searchItems,
      checkedAccounts,
      isGetAccountsInProgress,
      onItemsPerPageChange,
      companiesCounter,
      onCurrentPageChanged,
      pageCount,
      handleActions,
      formatDate,
      dialogVisible,
      deleteCompanyInput,
      accountToDeleteLabel,
      metrics,
      deleteLoading,
      getRoleName,
      onDeleteAccount

    }
  }
})
